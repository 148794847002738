import { getString } from '@/content/i18n';
import { mkOnClickKeyDown, OnClickKeyDownOutput } from '@/utils/a11y-utils';
import { Nullable, ReactNodeish, ValueOf } from '@/utils/types';
import Icon from '@/components/shared/icon/Icon';
import softError from '@/utils/softError';

import classnames from 'classnames';
import React from 'react';

const DEFAULT = 'DEFAULT';
const LARGE = 'LARGE';

export const SIZE = Object.freeze({
  DEFAULT,
  LARGE,
});

export type Size = ValueOf<typeof SIZE>;

type Props = {
  className?: Nullable<string>;
  size: Size;
  pageNumber: number; // Starts on page 1
  totalResults?: Nullable<number>;
  totalPages?: Nullable<number>;
  resultsPerPage?: Nullable<number>;
  maxVisiblePageButtons: number;
  onPaginate: (requestedPageNumber: number) => void;
};

export default class CLPagerBase extends React.PureComponent<Props> {
  static defaultProps = {
    pageNumber: 1,
    maxVisiblePageButtons: 5,
    size: SIZE.DEFAULT,
  };

  onClickPageNumber = (event: React.SyntheticEvent<HTMLElement>): void => {
    event.preventDefault();
    const pageNum = event.currentTarget.getAttribute('data-page-num');
    if (!pageNum) {
      softError('clPagerBase.clickPageNumberError', 'Error fetching attribute data-page-num');
      return;
    }
    const requestedPageNumber = parseInt(pageNum, 10);
    if (this.props.pageNumber !== requestedPageNumber) {
      this.props.onPaginate(requestedPageNumber);
    }
  };

  onClickPrev = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    this.props.onPaginate(this.props.pageNumber - 1);
  };

  onClickNext = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    this.props.onPaginate(this.props.pageNumber + 1);
  };

  _onClickKeyDownPageNumberProps: OnClickKeyDownOutput<HTMLElement> = mkOnClickKeyDown({
    onClick: this.onClickPageNumber,
  });

  _onClickKeyDownPrevProps: OnClickKeyDownOutput<HTMLElement> = mkOnClickKeyDown({
    onClick: this.onClickPrev,
  });

  _onClickKeyDownNextProps: OnClickKeyDownOutput<HTMLElement> = mkOnClickKeyDown({
    onClick: this.onClickNext,
  });

  render(): ReactNodeish {
    const {
      className,
      size,
      pageNumber,
      maxVisiblePageButtons,

      // Props which should not be passed though to the outer-most component
      /* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
      totalResults: _totalResults,
      totalPages: _totalPages,
      resultsPerPage: _resultsPerPage,
      onPaginate: _onPaginate,
      /* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */

      // Props which should be passed through to the outer-most component
      ...otherProps
    } = this.props;
    const totalPages = calcTotalPages(this.props);
    const hasPrev = pageNumber > 1; // Pages start at 1
    const hasNext = pageNumber < totalPages;

    const { visiblePageNumbers, hasLowerPages, hasHigherPages } = calcVisiblePages({
      pageNumber,
      maxVisiblePageButtons,
      totalPages,
    });

    if (visiblePageNumbers.length === 0) {
      // Don't render anything if there are no pages
      return null;
    }

    return (
      <div
        className={classnames(
          {
            'cl-pager': true,
            'cl-pager--size-default': size === DEFAULT,
            'cl-pager--size-large': size === LARGE,
            'cl-pager--has-prev-enabled': hasPrev,
            'cl-pager--has-next-enabled': hasNext,
            'cl-pager--has-lower-pages': hasLowerPages,
            'cl-pager--has-higher-pages': hasHigherPages,
          },
          className
        )}
        data-curr-page-num={pageNumber}
        data-total-pages={totalPages}
        {...otherProps}>
        <button
          disabled={!hasPrev}
          aria-label={getString(_ => _.componentLibrary.pager.ariaLabelPrevPage)}
          key="prev"
          className="cl-pager__button cl-pager__prev"
          data-test-id="previous-page"
          {...(hasPrev ? this._onClickKeyDownPrevProps : {})}>
          <div className="cl-pager__button-label">
            <Icon icon="arrow-lite" />
          </div>
        </button>
        <div
          aria-hidden={hasLowerPages ? undefined : true}
          key="lower"
          className="cl-pager__button cl-pager__button--is-disabled cl-pager__lower">
          <div className="cl-pager__button-label">...</div>
        </div>
        {visiblePageNumbers.map(pageNum => (
          <button
            key={pageNum}
            className={classnames({
              'cl-pager__button': true,
              'cl-pager__number': true,
              'cl-pager__number--is-active': pageNum === pageNumber,
            })}
            data-test-id={pageNum === pageNumber ? 'active-page' : 'inactive-page'}
            aria-label={
              pageNum === pageNumber
                ? getString(_ => _.componentLibrary.pager.ariaLabelNavigateCurrentPage, pageNum)
                : getString(_ => _.componentLibrary.pager.ariaLabelNavigateToPage, pageNum)
            }
            data-page-num={pageNum}
            {...this._onClickKeyDownPageNumberProps}>
            <div className="cl-pager__button-label">{pageNum.toLocaleString()}</div>
          </button>
        ))}
        <div
          aria-hidden={hasHigherPages ? undefined : true}
          key="higher"
          className="cl-pager__button cl-pager__button--is-disabled  cl-pager__higher">
          <div className="cl-pager__button-label">...</div>
        </div>
        <button
          disabled={!hasNext}
          aria-label={getString(_ => _.componentLibrary.pager.ariaLabelNextPage)}
          key="next"
          className="cl-pager__button cl-pager__next"
          data-test-id="next-page"
          {...(hasNext ? this._onClickKeyDownNextProps : {})}>
          <div className="cl-pager__button-label">
            <Icon icon="arrow-lite" />
          </div>
        </button>
      </div>
    );
  }
}

export function calcTotalPages({
  totalResults,
  totalPages,
  resultsPerPage,
}: {
  totalResults?: Nullable<number>;
  totalPages?: Nullable<number>;
  resultsPerPage?: Nullable<number>;
}): number {
  if (typeof totalPages === 'number' && totalPages >= 0) {
    // Use totalPages if passed
    return totalPages;
  }
  if (
    typeof totalResults === 'number' &&
    totalResults >= 0 &&
    typeof resultsPerPage === 'number' &&
    resultsPerPage >= 1
  ) {
    // Calculate totalPages from totalResults and resultsPerPage
    return Math.ceil(totalResults / resultsPerPage);
  }
  // No way of calculating, so return default
  return 0;
}

export function calcVisiblePages({
  pageNumber,
  maxVisiblePageButtons,
  totalPages,
}: {
  pageNumber: number;
  maxVisiblePageButtons: number;
  totalPages: number;
}): {
  visiblePageNumbers: number[];
  hasLowerPages: boolean;
  hasHigherPages: boolean;
} {
  // Determine what pages are visible
  const allPages = Array.from({ length: totalPages }).map((_, i) => i + 1);
  let visiblePageNumbers;
  if (totalPages <= maxVisiblePageButtons) {
    // Fewer pages than max, show all
    visiblePageNumbers = allPages;
  } else if (pageNumber < maxVisiblePageButtons / 2) {
    // No gap on lower side
    visiblePageNumbers = allPages.slice(0, maxVisiblePageButtons);
  } else if (totalPages - maxVisiblePageButtons / 2 < pageNumber) {
    // No gap on higher side
    visiblePageNumbers = allPages.slice(totalPages - maxVisiblePageButtons, totalPages);
  } else {
    // Take equal buttons on each side (more on higher if passed even number)
    visiblePageNumbers = allPages.slice(
      pageNumber - Math.ceil(maxVisiblePageButtons / 2),
      pageNumber + Math.floor(maxVisiblePageButtons / 2)
    );
  }

  // Determine if there are more pages on either side
  const firstVisibleNumber = visiblePageNumbers[0];
  const lastVisibleNumber = visiblePageNumbers[visiblePageNumbers.length - 1];
  const hasLowerPages = typeof firstVisibleNumber === 'number' && firstVisibleNumber !== 1;
  const hasHigherPages = typeof lastVisibleNumber === 'number' && lastVisibleNumber !== totalPages;

  return {
    visiblePageNumbers,
    hasLowerPages,
    hasHigherPages,
  };
}
