import CLPagerBase, { SIZE as _SIZE, Size } from './CLPagerBase';

import { ExampleConfig } from '@/components/library/ExampleUtils';
import { Nullable, ReactNodeish } from '@/utils/types';

import React from 'react';

export const SIZE = _SIZE;

type Props = {
  className?: Nullable<string>;
  size: Size;
  pageNumber: number; // Starts on page 1
  totalResults?: Nullable<number>;
  totalPages?: Nullable<number>;
  resultsPerPage?: Nullable<number>;
  maxVisiblePageButtons: number;
  onPaginate: (requestedPageNumber: number) => void;
};

export default function CLPager(props: Props): ReactNodeish {
  return <CLPagerBase {...props} />;
}

CLPager.defaultProps = {
  maxVisiblePageButtons: CLPagerBase.defaultProps.maxVisiblePageButtons,
  size: CLPagerBase.defaultProps.size,
};

export const exampleConfig: ExampleConfig = {
  getComponent: () => CLPager,
  fields: [
    {
      name: 'pageNumber',
      desc: 'Current number for the page',
      value: {
        type: 'number',
        default: 1,
      },
    },
    {
      name: 'totalPages',
      desc: 'Number of pages that can be navigated to (takes priority over totalResults and resultsPerPage)',
      value: {
        type: 'number',
        default: 10,
      },
    },
    {
      name: 'maxVisiblePageButtons',
      desc: 'Number of buttons that can be rendered at once',
      value: {
        type: 'number',
        default: CLPager.defaultProps.maxVisiblePageButtons,
      },
    },
    {
      name: 'totalResults',
      desc: 'Number of results which are in the list',
      value: {
        type: 'number',
        default: null,
      },
    },
    {
      name: 'resultsPerPage',
      desc: 'Number of results that are rendered per page',
      value: {
        type: 'number',
        default: null,
      },
    },
    {
      name: 'size',
      desc: 'Space taken up by the pager buttons',
      value: {
        type: 'select',
        default: CLPager.defaultProps.size,
        options: Object.keys(SIZE),
      },
    },
    {
      name: 'className',
      desc: 'HTML classes to be added to pager',
      value: {
        type: 'text',
        default: '',
      },
    },
  ],

  examples: [
    {
      title: 'Page 1 of 10',
      desc: 'Pagination starting at page 1',
      props: {
        pageNumber: 1,
        totalPages: 10,
        maxVisiblePageButtons: 5,
        className: '',
      },
    },
    {
      title: 'Page 2 of 10',
      desc: 'Pagination starting at page 2',
      props: {
        pageNumber: 2,
        totalPages: 10,
        maxVisiblePageButtons: 5,
        className: '',
      },
    },
    {
      title: 'Page 4 of 10',
      desc: 'Pagination starting at page 4',
      props: {
        pageNumber: 4,
        totalPages: 10,
        maxVisiblePageButtons: 5,
        className: '',
      },
    },
    {
      title: 'Page 9 of 10',
      desc: 'Pagination starting at page 9',
      props: {
        pageNumber: 9,
        totalPages: 10,
        maxVisiblePageButtons: 5,
        className: '',
      },
    },
    {
      title: 'Page 10 of 10',
      desc: 'Pagination starting at page 10',
      props: {
        pageNumber: 10,
        totalPages: 10,
        maxVisiblePageButtons: 5,
        className: '',
      },
    },
    {
      title: 'Page 5 of 10, Large',
      desc: 'Pagination starting at page 5, on a mobile device',
      props: {
        pageNumber: 5,
        totalPages: 10,
        maxVisiblePageButtons: 5,
        size: SIZE.LARGE,
        className: '',
      },
    },
    {
      title: 'Page 3 of 7, showing 4 numbers',
      desc: 'Pagination starting at page 3, but only showing 4 numbers at a time',
      props: {
        pageNumber: 3,
        totalPages: 7,
        maxVisiblePageButtons: 4,
        className: '',
      },
    },
  ],

  events: {
    onPaginate: requestedPageNumber => ({
      requestedPageNumber,
    }),
  },
};
