import { addHeapProps } from '@/components/util/HeapTracking';
import { getString } from '@/content/i18n';
import { HeapProps } from '@/analytics/heapUtils';
import { heapSortSelect } from '@/analytics/attributes/searchFilters';
import { Nullable, ReactNodeish, TODO } from '@/utils/types';
import { SortRecord } from '@/models/Sort';
import EventTarget from '@/analytics/constants/EventTarget';
import Icon from '@/components/shared/icon/Icon';
import SortEvent from '@/analytics/models/SortEvent';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

type Props = {
  className?: Nullable<string>;
  sort: string;
  onChangeSort: (sort: string) => void;
  options: Immutable.List<SortRecord>;
  heapProps?: Nullable<HeapProps>;
};

type TODO__AnalyticsLocation = TODO<'AnalyticsLocation'>;

export default class SortSelector extends React.PureComponent<Props> {
  declare context: {
    analyticsLocation: TODO__AnalyticsLocation;
  };

  static contextTypes = {
    analyticsLocation: PropTypes.object,
  };

  handleChangeSort = (sortOrEvent: string | React.ChangeEvent<HTMLInputElement>): void => {
    const sort = typeof sortOrEvent === 'string' ? sortOrEvent : sortOrEvent.target.value;

    this.props.onChangeSort && this.props.onChangeSort(sort);

    const analyticsLocation = this.context.analyticsLocation;
    if (analyticsLocation) {
      const eventTarget = EventTarget.getIn(analyticsLocation, 'SORT_BY');
      trackAnalyticsEvent(SortEvent.create(eventTarget, { sort }));
    } else {
      trackAnalyticsEvent(SortEvent.create(EventTarget.SORT_BY, { sort }));
    }
  };

  render(): ReactNodeish {
    const { className, heapProps } = this.props;
    const cssClasses = classnames(className, 'search-sort flex-row-vcenter');

    return (
      <div className={cssClasses}>
        <select
          className="legacy__select search-sort-select"
          onChange={this.handleChangeSort}
          value={this.props.sort}
          {...(heapProps ? addHeapProps(heapProps) : heapSortSelect())}
          data-test-id="sort-select">
          {this.props.options.map(sort => {
            const sortName = getString(_ => _.sort[sort.id].label);
            return (
              <option value={sort.id} key={sort.id} data-test-id={`sort-by-${sort.id}`}>
                {getString(_ => _.sort.label, sortName)}
              </option>
            );
          })}
        </select>
        <Icon icon="disclosure" height="12" width="12" className="dropdown-arrow-icon" />
      </div>
    );
  }
}
