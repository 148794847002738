import { mkHeapAttributes } from '@/analytics/heapUtils';

export const heapHasPdfButton = () =>
  mkHeapAttributes({
    id: 'pdf_available_filter_button',
  });

export const heapHasPdfFacet = () =>
  mkHeapAttributes({
    id: 'facet_has_pdf',
  });

// TODO(#35824): facetType is passed camelCased, but heap IDs should be lower_snake_cased
export const heapSearchFacet = (facetType: string) =>
  mkHeapAttributes({
    id: `facet_${facetType}`,
  });

export const heapYearRange = () =>
  mkHeapAttributes({
    id: 'year_range_presets',
  });

export const heapSortSelect = () =>
  mkHeapAttributes({
    id: 'sort_select',
  });
