import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class FlexItem extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    width: PropTypes.string,
    testId: PropTypes.string,
  };

  render() {
    const { width, children, testId, className, ...rest } = this.props;
    const classes = classnames('flex-item', width ? `flex-item--width-${width}` : '', className);

    return (
      <div className={classes} {...rest} data-test-id={testId}>
        {children}
      </div>
    );
  }
}
