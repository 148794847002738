import AnalyticsEvent from './AnalyticsEvent';

import EventType from '@/analytics/constants/EventType';

import merge from 'merge';

/** Event tracking when the sort order is changed. */
export default class SortEvent extends AnalyticsEvent {
  /**
   * @param {string} target - an identifier for what was selected.
   * @param {object} eventData - optional key value pairs describing the event.
   */
  constructor(target: string, data?: any) {
    super(EventType.SORT, merge.recursive({ target }, data));
  }

  static create(target: string, data?: any): SortEvent {
    return new SortEvent(target, data);
  }
}
