import { TODO } from '@/utils/types';

import PropTypes from 'prop-types';
import React from 'react';

export const AnalyticsLocationContext = React.createContext({});

/**
 * Higher Order Component generator for labeling components with location context for analytics.
 *
 * @param locationObject - An object to be provided to context as the current location
 * @returns A curried function capable of wrapping a passed Component
 */
export default function withAnalyticsLocation(
  locationObject: Record<string, TODO<'Restrict this to EventTarget and its children'>>
) {
  // This and the empty function definition below it are TS Overloads: https://www.typescriptlang.org/docs/handbook/2/functions.html#function-overloads
  // The normal function type wasn't inferring class component props. This overload makes it infer properly while still keeping TS happy in the render function in withAnalyticsLocation.
  function composeComponent<
    TComponent extends React.ComponentType,
    TComponentProps = React.ComponentProps<TComponent>,
  >(ComposedComponent: TComponent): React.ComponentType<TComponentProps>;

  // we need to expose the actual implementation with an overload or TS won't see it.
  function composeComponent<TComponentProps = Record<string, unknown>>(
    ComposedComponent: React.ComponentType<TComponentProps>
  ): React.ComponentType<TComponentProps>;

  /**
   * @param ComposedComponent - The React Component to be wrapped
   * @returns Location context wrapped Component
   */
  function composeComponent<TComponentProps = Record<string, unknown>>(
    ComposedComponent: React.ComponentType<TComponentProps>
  ): React.ComponentType<TComponentProps> {
    return class WithAnalyticsLocation extends React.Component<TComponentProps> {
      static childContextTypes = {
        analyticsLocation: PropTypes.object,
      };

      static contextTypes = {
        analyticsLocation: PropTypes.object,
      };

      getChildContext() {
        return {
          analyticsLocation: locationObject,
        };
      }

      render() {
        return (
          <AnalyticsLocationContext.Provider value={locationObject}>
            <ComposedComponent {...this.props} />
          </AnalyticsLocationContext.Provider>
        );
      }
    };
  }

  return composeComponent;
}
