import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

/**
 * A component that uses flexbox to lay out components as rows and/or columns.
 * You can specify a layout direction per screen size, which will also apply to
 * all smaller sizes unless overriden. If no layout is set the default is 'row'.
 */
export default class FlexContainer extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    layout: PropTypes.oneOf(['row', 'column']),
    layoutLg: PropTypes.oneOf(['row', 'column']),
    layoutMed: PropTypes.oneOf(['row', 'column', 'column-reverse']),
    layoutSm: PropTypes.oneOf(['row', 'column']),
    position: PropTypes.oneOf(['static', 'relative', 'absolute', 'fixed']),
    wrap: PropTypes.bool,
    testId: PropTypes.string,
    id: PropTypes.string,
    role: PropTypes.string,
  };

  static defaultProps = {
    testId: null,
    id: null,
    role: null,
  };

  render() {
    const className = classnames(
      'flex-container',
      {
        [`flex-${this.props.layout}`]: this.props.layout,
        [`flex-${this.props.layoutLg}-lg`]: this.props.layoutLg,
        [`flex-${this.props.layoutMed}-med`]: this.props.layoutMed,
        [`flex-${this.props.layoutSm}-sm`]: this.props.layoutSm,
        [`flex-${this.props.position}-pos`]: this.props.position,
        'flex-wrap': this.props.wrap,
      },
      this.props.className
    );
    return (
      <div
        className={className}
        data-test-id={this.props.testId}
        role={this.props.role}
        id={this.props.id}>
        {this.props.children}
      </div>
    );
  }
}
